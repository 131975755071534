<template>
    <v-container class="fadeInFast">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>

        <HeadlineTableView
                :icon="'mdi-calendar'"
                :headline="'Termine'"
                :button-icon="'mdi-calendar'"
                :button-text="'Neuer Termin'"
                @buttonClick="openDialogCreateEvent()"
        ></HeadlineTableView>


        <div class="g_id_signin" data-type="standard"></div>
        <button id="authorize_button" @click="test">Authorize</button>
        <br><br>
        <button id="signout_button" @click="handleSignoutClick">Sign Out</button>
        <br><br>
        <button id="signout_button" @click="listUpcomingEvents">Test get</button>
        <br><br>


        <pre id="contentSe" style="white-space: pre-wrap;"></pre>
        <v-card flat class="rounded-xl pa-2 pa-sm-4">
            <CalendarComponent
                    v-if="loaded"
                    :events="events"
                    @deleteEvent="deleteEvent($event)"
            ></CalendarComponent>
            <Spinner v-else></Spinner>

            <CreateEvent
                    :dialog="dialog"
                    @hideDialog="hideDialog"
            ></CreateEvent>
        </v-card>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const CalendarComponent = () => import("@/components/entities/calendar/Calendar");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const Message = () => import("@/components/generalUI/Message");
const CreateEvent = () => import("@/components/dialog/createDialogs/CreateEvent");
const Spinner = () => import("@/components/generalUI/Spinner");
// Client ID and API key from the Developer Console
const CLIENT_ID = "786497755916-ok3j7stmms1rkiqmb0lanjsvpnme39nv.apps.googleusercontent.com\n";
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";
const API_KEY = "AIzaSyDyVoiwkHOTsHLQV5SKqaw9Ek2saxsW8g0";
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
let tokenClient;
export default {
    name: "Calendar",
    components: {
        CalendarComponent,
        HeadlineTableView,
        Message,
        CreateEvent,
        Spinner
    },
    computed: {
        ...mapGetters('auth', {
            user: 'user',
        }),
        ...mapGetters('calendar', {
            events: 'events',
        }),
    },

    data() {
        return {
            dialog: false,
            message: msgObj(),
            datetime: null,
            loaded: false,
            api: undefined,
            authorized: false,

            gisInited: false,
            gapiInited: false
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.$store.dispatch('auth/checkLogin', {}).then((res) => {
            this.$store.dispatch('calendar/getEvents', {
                uid: res
            }).then(() => {
                this.loaded = true
                this.gisLoaded();
                this.gapiLoaded()
            }).catch((err) => {
                this.message = error(err)
            })
        })
    },

    methods: {
        handleAuthClick() {
            tokenClient.callback = async (resp) => {
                if (resp.error !== undefined) {
                    console.log("test1")
                    throw (resp);
                }
                document.getElementById('signout_button').style.visibility = 'visible';
                document.getElementById('authorize_button').innerText = 'Refresh';
                await this.listUpcomingEvents();
            };

            // eslint-disable-next-line no-undef
            if (gapi.client.getToken() === null) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                console.log("Angemeldet")
                tokenClient.requestAccessToken({prompt: 'consent'});
            } else {
                // Skip display of account chooser and consent dialog for an existing session.
                console.log("Angemeldet else")
                tokenClient.requestAccessToken({prompt: ''});
            }
        },

        async listUpcomingEvents() {
            let response;
            try {
                const request = {
                    'calendarId': 'primary'
                };
                // eslint-disable-next-line no-undef
                response = await gapi.client.calendar.events.list(request);
            } catch (err) {
                console.log(err.message)
                document.getElementById('contentSe').innerText = err.message;
                return;
            }

            const events = response.result.items;
            if (!events || events.length == 0) {
                console.log('No events found.')
                document.getElementById('contentSe').innerText = 'No events found.';
                return;
            }
            // Flatten to string to display
            const output = events.reduce(
                (str, event) => `${str}${event.summary} (${event.start.dateTime || event.start.date})\n`,
                'Events:\n');
            console.log(output)
            document.getElementById('contentSe').innerText = output;
        },
        test() {
            tokenClient.callback = async (resp) => {
                if (resp.error !== undefined) {
                    throw (resp);
                }
                document.getElementById('signout_button').style.visibility = 'visible';
                document.getElementById('authorize_button').innerText = 'Refresh';
                await this.listUpcomingEvents();
            };

            // eslint-disable-next-line no-undef
            if (gapi.client.getToken() === null) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                tokenClient.requestAccessToken({prompt: 'consent'});
            } else {
                // Skip display of account chooser and consent dialog for an existing session.
                tokenClient.requestAccessToken({prompt: ''});
            }
        },
        gapiLoaded() {
            // eslint-disable-next-line no-undef
            gapi.load('client', this.initializeGapiClient);
        },

        gisLoaded() {
            // eslint-disable-next-line no-undef
            tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: CLIENT_ID,
                scope: SCOPES,
                callback: '', // defined later
            });
            this.gisInited = true;
            this.maybeEnableButtons();
        },
        maybeEnableButtons() {
            if (this.gapiInited && this.gisInited) {
                document.getElementById('authorize_button').style.visibility = 'visible';
            }
        },
        async initializeGapiClient() {
            // eslint-disable-next-line no-undef
            await gapi.client.init({
                apiKey: API_KEY,
                discoveryDocs: DISCOVERY_DOCS,
            });
            this.gapiInited = true;
            this.maybeEnableButtons();
        },
        handleSignoutClick() {
            // eslint-disable-next-line no-undef
            const token = gapi.client.getToken();
            if (token !== null)
            {
                // eslint-disable-next-line no-undef
                google.accounts.oauth2.revoke(token.access_token);
                // eslint-disable-next-line no-undef
                gapi.client.setToken('');
                document.getElementById('content').innerText = '';
                document.getElementById('authorize_button').innerText = 'Authorize';
                document.getElementById('signout_button').style.visibility = 'hidden';
            }else{
                console.log("Token is null")
            }
        },
        openDialogCreateEvent() {
            this.dialog = true
        },
        hideDialog() {
            this.dialog = false
        },
        deleteEvent(payload) {
            console.log(payload)
            this.$store.dispatch('calendar/deleteEvent', {
                eventId: payload.eventId,
                uid: this.user.id
            }).then(() => {
                    this.message = success("Termin wurde erfolgreich gelöscht.")
                }
            ).catch((err) => {
                this.message = error(err)
            })
        },
    }
}
</script>

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)

  > .v-card__text
    color: #000
</style>
<style scoped>
.border {
    border-right: 1px solid grey
}
</style>
